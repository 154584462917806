import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/**
 * Wrong user persist dialog component
 */
@Component({
    selector: 'mona-wrong-user-persist-dialog',
    templateUrl: './wrong-user-persist-dialog.component.html',
    styleUrls: ['./wrong-user-persist-dialog.component.scss'],
})
export class WrongUserPersistDialogComponent {
    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<WrongUserPersistDialogComponent>
     * @param data
     */
    constructor(
        private dialogRef: MatDialogRef<WrongUserPersistDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { isPersisted: boolean },
    ) {}
}
