import { inject, Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';
import { IpcMainEvent } from '@mona/events';
import { MonaRpcService } from '@mona/rpc';
import { AppState } from '@mona/store';
import { AuthFlow, AuthState, AuthTypeEnum, TokenStatus, User } from '../models';
import { AuthActions, AuthSelectors } from '../state';

type State = AppState & { auth: AuthState };
type HealthSettings = any;

/**
 * Authenticated {@link User} object as observable
 */
export function currentUser$() {
    const store = inject(Store);
    return store.select(AuthSelectors.selectUser);
}

/**
 * Auth service
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    /** Is authorization in progress */
    isLoading$: Observable<boolean> = this.store.select(AuthSelectors.selectIsLoading);
    /** Event of logged out action */
    isLoggedOut$ = this.actionsObserver$.pipe(ofType(AuthActions.logOut));
    /** Get login action */
    isLoggedIn$ = this.actionsObserver$.pipe(ofType(AuthActions.loginSuccess));
    /** Is authorization with error */
    error$: Observable<any> = this.store.select(AuthSelectors.selectError);
    /** Authenticated user object */
    user$: Observable<User> = this.store.select(AuthSelectors.selectUser);
    /** is disacard in progress */
    isDiscardInProgress$ = this.store
        .select(state => state?.changelogData)
        .pipe(map(actions => actions?.discardChangesAction?.inProgress));
    /** Is User authenticated */
    isAuthenticated$ = this.store.select(AuthSelectors.selectIsAuthenticated);
    /**
     * Is User activated
     *
     * @description To detect user registration  & show message `Your badge has been successfully
     * activated and you can now use it to log in. The activation code is no longer required.`
     */
    activatedRFIDCard$ = this.store.select(AuthSelectors.selectActivatedRFIDCard);
    /**
     * ⚠️ Is user currently being logged out - temporary workaround to prevent multiple 401
     */
    readonly isLoggingOut$ = new BehaviorSubject<boolean>(false);

    /* Get last scanned/emulated rfid - not memoized
     *
     * 🛈 Does not use selector because need to emit observable when the same rfid is scanned
     */
    rfidScanned$: Observable<string> = this.actionsObserver$.pipe(ofType(AuthActions.scanRfid), pluck('rfid'));
    /**
     * Bypasses RFID scan block
     */
    ignoreCallBlockingRfid: boolean;
    /**
     * Bypasses RFID scan block
     */
    currentUserRfid$: Observable<string> = this.store.select(AuthSelectors.selectUserRfid);

    /**
     * Do not dispatch rfid due to diagnostics or missing licenses
     */
    private skipApiVerification: boolean;

    /**
     * Constructor
     *
     * @param store
     * @param actionsObserver$
     * @param rpcService
     */
    constructor(
        private store: Store<any>,
        private actionsObserver$: ActionsSubject,
        private rpcService: MonaRpcService,
    ) {}

    /**
     * Returns a promise that waits until
     * refresh token and get auth user
     *
     * @returns {Promise<AuthState>}
     */
    init(): Promise<AuthState> {
        /* this.store.dispatch(AuthActions.refreshTokenRequest());

        const authState$ = this.store.select(AuthSelectors.selectAuthState).pipe(
            filter(
                auth =>
                    auth.refreshTokenStatus === TokenStatus.INVALID ||
                    (auth.refreshTokenStatus === TokenStatus.VALID && !!auth.user),
            ),
            take(1),
        );

        return authState$.toPromise(); */
        return Promise.resolve({} as any);
    }

    /**
     * Dispatch logInWithCredentials event
     *
     * @param username
     * @param password
     * @param authFlow
     */
    loginWithCredentials(username: string, password: string, authFlow: AuthFlow = AuthFlow.signin): void {
        this.isLoggingOut$.next(false); // TODO: remove after refresh token implmenetation
        this.store.dispatch(AuthActions.logInWithCredentials({ payload: { username, password }, authFlow }));
    }

    /**
     * Dispatch logInWithRfid event
     *
     * @param rfid
     */
    loginWithRfid(rfid: string) {
        this.isLoggingOut$.next(false); // TODO: remove after refresh token implmenetation
        this.store.dispatch(AuthActions.logInWithRfid({ payload: { rfid } }));
    }

    /**
     * Get permissions
     *
     * @param rfid rfid rfid number
     */
    loadPermissions(rfid: string) {
        this.store.dispatch(AuthActions.loadPermissions({ rfid }));
    }

    /**
     * Toggles `skipApiVerification` if api sign-in should be skipped
     *
     * @param skipApiVerification
     */
    setSkipApiVerification(skipApiVerification: boolean) {
        this.skipApiVerification = skipApiVerification;
    }

    /**
     * Requests an rfid from the user and returns the rfid or null if canceled
     *
     * @param authFlow auth flow
     * @param authType sign-in-type
     * @param isDiagnostics false
     * @param forceRelogin
     */
    authenticate(
        authFlow: AuthFlow = AuthFlow.verify,
        authType: AuthTypeEnum = AuthTypeEnum.All,
        isDiagnostics = false,
        forceRelogin = false,
    ): Observable<string> {
        // Toggle `skipApiVerification` if api sign-in should be skipped
        const _oldSkipApiVerification = this.skipApiVerification;
        if (isDiagnostics) {
            this.setSkipApiVerification(isDiagnostics);
        }
        // Dispatch & listen for close action with payload
        this.store.dispatch(AuthActions.authenticateOpen({ authType, authFlow }));
        return this.actionsObserver$.pipe(
            ofType(AuthActions.authenticateClose),
            take(1),
            map(({ rfid }) => {
                // Toggle `skipApiVerification` if api sign-in should be skipped
                if (isDiagnostics) {
                    this.setSkipApiVerification(_oldSkipApiVerification);
                }
                return rfid;
            }),
        );
    }

    /**
     * Request rfid close
     *
     * @param rfid
     */
    authenticateClose(rfid: string) {
        this.store.dispatch(AuthActions.authenticateClose({ rfid }));
    }

    /**
     * Get device access token
     *
     * @param isTelemedicine
     */
    getDeviceAccessToken(isTelemedicine = false): Promise<string> {
        return this.rpcService.invoke<string>(IpcMainEvent.GET_JWT_TOKEN, { isTelemedicine });
    }

    /**
     * Identify the user with the given RFID
     *
     * @param rfid rfid
     * @deprecated
     * @todo: why this hasn't been removed if not used ?
     */
    verifyRfId(rfid: string) {
        combineLatest([
            // TODO: fix those parent state selectors
            this.store.select((state: any) => state.call?.session?.status),
            this.store.select((state: any) => state.api.healthState).pipe(filter<HealthSettings>(Boolean)),
        ])
            .pipe(
                skipWhile(() => this.skipApiVerification),
                take(1),
            )
            .subscribe(([callStatus, healthState]: [string, HealthSettings]) => {
                // Verify RFID under correct conditions
                if (
                    (this.ignoreCallBlockingRfid || !callStatus) &&
                    !healthState?.maintenance &&
                    !healthState?.isVersionIncompatible
                ) {
                    this.dispatchVerifyRfid(rfid);
                }
            });
    }

    /**
     * Dispatches verify rfid action without any conditions
     *
     * @param rfid string
     * @param dismissToast boolean
     */
    dispatchVerifyRfid(rfid: string, dismissToast = false) {
        this.store.dispatch(AuthActions.verifyRfid({ rfid, dismissToast }));
    }

    /**
     * Listen to rfid scanner
     */
    listenToRfidScanner() {
        this.rpcService.invoke<string>(IpcMainEvent.AWAIT_RFID_SCAN).then(rfid => {
            this.store.dispatch(AuthActions.scanRfid({ rfid }));
            // Restart listener in any case
            this.listenToRfidScanner();
        });
    }

    /**
     * Emulate rfid on F key number send from keyboard
     *
     * @param key
     */
    emulateRfid(key: number): void {
        this.rpcService.invoke<string>(IpcMainEvent.EMULATE_RFID_HANDLE, key).then(rfid => {
            this.store.dispatch(AuthActions.emulateRfid({ rfid }));
        });
    }

    /**
     * Registers an rfid to a user
     *
     * @param rfid string
     * @param pin string
     */
    registerRfid(rfid: string, pin: string) {
        this.store.dispatch(AuthActions.registerRfid({ rfid, pin }));
    }

    /**
     * Update attached rfid card
     */
    updateRfid() {
        this.store.dispatch(AuthActions.updateRfid());
    }

    /**
     * Discard attached rfid card
     *
     * @param rfid string
     */
    discardRfid(rfid: string) {
        this.store.dispatch(AuthActions.discardRfid({ rfid }));
    }

    /**
     * Dispatch Log Out action
     *
     * @param error
     * @param skipDiscardDialog
     * @param isUserChangedDuringPersist
     */
    logOut(error = null, skipDiscardDialog = true, isUserChangedDuringPersist = false) {
        this.store.dispatch(AuthActions.logOut({ error, skipDiscardDialog, isUserChangedDuringPersist }));
    }

    /**
     * Dispatch Log Out success
     */
    logOutSuccess() {
        this.store.dispatch(AuthActions.logOutSuccess());
    }

    /**
     * Dispatch clear auth error action
     */
    clearError() {
        this.store.dispatch(AuthActions.clearAuthError());
    }
}
