import { ROUTES_MAP_BY_FEATURE, RouteWithData } from '@environment';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from '@mona/auth';
import { DeviceRegisteredGuard } from '@mona/device/data-access-device';
import { FeatureFlagsGuard } from '@mona/flags';
import { NavigateClearOutletsGuard, PlatformElectronGuard } from '@mona/shared/utils';
import { DrawerSizeEnum, SettingsLayoutComponent, UserSettingsLayoutComponent } from '@mona/ui';

/**
 * MONA_ROUTES
 */
export const MONA_ROUTES: RouteWithData[] = [
    // -------------------------------------------------------------------------
    // ℹ️ Setup (aka OOBE aka Out of the box experience)
    // -------------------------------------------------------------------------
    {
        path: 'device/setup',
        canLoad: [DeviceRegisteredGuard],
        canActivate: [DeviceRegisteredGuard],
        data: {
            checkFeature: ':mona',
            allowOffline: true,
            layoutConfig: {
                hasSidenav: false,
                hasNavbar: false,
            },
        },
        loadChildren: () => import('@mona/device/setup').then(m => m.DeviceSetupModule),
    },
    // -------------------------------------------------------------------------
    // TELEMEDICINE
    // -------------------------------------------------------------------------
    {
        path: 'telemedicine',
        canLoad: [FeatureFlagsGuard],
        canActivate: [AuthGuard],
        data: {
            checkFeature: 'telemedicine:mona',
            layoutConfig: {
                hasSidenav: true,
                hasNavbar: false,
                size: DrawerSizeEnum.LARGE,
            },
        },
        loadChildren: () => import('@mona/telemedicine/shell').then(m => m.TelemedicineShellModule),
    },
    // -------------------------------------------------------------------------
    // PDMS
    // -------------------------------------------------------------------------
    // FIXME: lazyload
    // {
    //     path: 'pdms',
    //     canLoad: [FeatureFlagsGuard],
    //     data: {
    //         checkFeature: 'pdms',
    //     },
    //     loadChildren: () => import('@mona/pdms/shell').then(m => m.PdmsShellModule),
    // },
    // -------------------------------------------------------------------------
    // SETTINGS
    // -------------------------------------------------------------------------
    {
        path: 'settings',
        component: SettingsLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [NavigateClearOutletsGuard],
        data: {
            // animation: 'SETTINGS',
            layoutConfig: {
                hasSidenav: true,
                collapsible: true,
                hasSidenavBar: true,
                hasNavbar: true,
                hasAppbar: false,
                title: 'apps.settings.screenTitle',
                subtitle: '',
                size: DrawerSizeEnum.LARGE,
                bgImageUrl: 'assets/images/settings.svg',
                items: [
                    {
                        title: 'apps.settings.device.title',
                        link: '/settings/device',
                        icon: 'display_settings',
                    },
                    {
                        title: 'apps.settings.mona.title',
                        link: '/settings/mona',
                        icon: 'install_desktop',
                    },
                    {
                        title: 'apps.settings.medical.title',
                        link: '/settings/pdms',
                        svgIcon: 'hospital-building',
                        key: 'pdms',
                    },
                ],
            },
        },
        children: [
            {
                path: '',
                redirectTo: 'device',
                pathMatch: 'full',
            },
            {
                path: 'device',
                loadChildren: () => import('@mona/device/settings-device').then(m => m.DeviceSettingsModule),
            },
            {
                path: 'mona',
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'settings_mona_view',
                        redirectTo: ROUTES_MAP_BY_FEATURE.SETTINGS[0],
                    },
                },
                loadChildren: () => import('@mona/device/settings-mona').then(m => m.MonaSettingsModule),
            },
            {
                path: 'pdms',
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'settings_medical_view',
                        redirectTo: ROUTES_MAP_BY_FEATURE.SETTINGS[0],
                    },
                },
                loadChildren: () => import('@mona/pdms/settings').then(m => m.PdmsSettingsModule),
            },
        ],
    },
    {
        path: 'user-settings',
        component: UserSettingsLayoutComponent,
        canActivate: [AuthGuard, PlatformElectronGuard],
        canActivateChild: [NavigateClearOutletsGuard],
        data: {
            layoutConfig: {
                hasSidenav: true,
                collapsible: true,
                hasSidenavBar: true,
                hasNavbar: true,
                hasAppbar: false,
                title: 'apps.userSettings.screenTitle',
                size: DrawerSizeEnum.MEDIUM,
                bgImageUrl: '',
                items: [
                    {
                        title: 'apps.userSettings.rfid.title',
                        link: '/user-settings/rfid',
                        svgIcon: 'rfid-card',
                        key: 'pdms',
                    },
                ],
            },
        },
        children: [
            {
                path: '',
                redirectTo: 'rfid',
                pathMatch: 'full',
            },
            {
                path: 'rfid',
                loadChildren: () => import('@mona/pdms/rfid-management').then(m => m.PdmsRfidManagementModule),
            },
        ],
    },
    // -------------------------------------------------------------------------
    // ℹ️ Outlets - should be declared in root route config to properly initialize
    // -------------------------------------------------------------------------
    {
        path: 'pdms/history',
        outlet: 'side',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
            mode: 'push',
        },
        loadChildren: () => import('@mona/pdms/history').then(m => m.PdmsHistoryModule),
    },
    {
        path: 'pdms/notifications',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
        },
        outlet: 'side',
        loadChildren: () => import('@mona/pdms/notifications').then(m => m.PdmsNotificationsModule),
    },
    {
        path: 'pdms/medication',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
        },
        outlet: 'side',
        loadChildren: () => import('@mona/pdms/medication').then(m => m.PdmsMedicationModule),
    },
    {
        path: 'pdms/procedure',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
        },
        outlet: 'side',
        loadChildren: () => import('@mona/pdms/procedure').then(m => m.PdmsProcedureModule),
    },
    {
        path: 'media/camera',
        outlet: 'dialog',
        loadChildren: () => import('@mona/telemedicine/shared').then(m => m.CAMERA_DIALOG_ROUTES),
    },
    {
        path: 'media/microphone',
        outlet: 'dialog',
        loadChildren: () => import('@mona/telemedicine/shared').then(m => m.SOUND_DIALOG_ROUTES),
    },
    {
        path: 'diagnostics/server-unavailable',
        outlet: 'dialog',
        loadChildren: () => import('@mona/api').then(m => m.SERVER_UNAVAILABLE_DIALOG_ROUTES),
    },
    {
        path: 'pdms/task-list',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
        },
        outlet: 'side',
        loadChildren: () => import('@mona/pdms/task-list').then(m => m.PdmsTaskListModule),
    },
    {
        path: 'pdms/patient-management',
        canLoad: [FeatureFlagsGuard],
        data: {
            checkFeature: 'pdms',
        },
        outlet: 'side',
        loadChildren: () => import('@mona/pdms/patient-management').then(m => m.PatientManagementModule),
    },

    // -------------------------------------------------------------------------
    // ℹ️ Always redirect to Device Setup - for WEB this navigation will be ignored
    // -------------------------------------------------------------------------
    {
        path: '',
        redirectTo: 'device/setup',
        pathMatch: 'full',
    },
];
