import { Injectable } from '@angular/core';
import { ROUTES_MAP_BY_FEATURE } from '@environment';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthFlow, AuthService, currentUser$ } from '@mona/auth';
import { ChangeLogEntry, ChangeLogModel, Encounter } from '@mona/models';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { selectSelectedEncounter } from '@mona/pdms/data-access-encounters';
import { AppError } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect, RouterActions } from '@mona/store';
import { MessageService } from '@mona/ui';
import { ChangeLogService } from '../../application';
import { ChangeLogApi, extendPayloadWithMetaData } from '../../infrastructure';
import { ChangeLogAction } from '../actions';
import * as ChangeLogSelectors from '../selectors';

/**
 * Change log effects
 */
@Injectable({ providedIn: 'root' })
export class ChangeLogEffects {
    /** Selector for current encounter  */
    private readonly currentEncounter$: Observable<Encounter> = this.store.select(selectSelectedEncounter);

    /** current practitioner */
    private readonly practitioner$ = currentUser$();

    /* Effect Declarations */

    /**
     * Load changes effect
     *
     * Should get `encounterId` from current state if not provided
     */
    loadChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.loadChangesAction.action),
            withLatestFrom(this.currentEncounter$.pipe(map(e => e?.id))),
            switchMap(([action, encounterId]) =>
                makeDefaultAsyncActionEffect(
                    this.changeLogApi.loadChanges(encounterId || action.encounterId),
                    ChangeLogAction.loadChangesAction,
                ),
            ),
        ),
    );

    /**
     * Save change effect
     */
    saveChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.saveChangeAction.action),
            concatLatestFrom(() => [this.currentEncounter$, this.authService.user$]),
            map(([{ change }, encounter, practitioner]) => ({
                // INFO: extend encounter info for change here
                // to prevent unneccessary arguments in changelog-service
                ...change,
                payload: extendPayloadWithMetaData(change.payload, encounter, practitioner),
            })),
            mergeMap(change =>
                makeDefaultAsyncActionEffect(
                    this.changeLogApi.saveChange(change, change.payload.encounterId),
                    ChangeLogAction.saveChangeAction,
                ),
            ),
        ),
    );

    /**
     * Save multiple changes effect
     */
    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.saveChangesAction.action),
            concatLatestFrom(() => [this.currentEncounter$, this.authService.user$]),
            map(([{ changes }, encounter, practitioner]) =>
                changes.map((change, idx) => ({
                    // INFO: extend encounter info for change here
                    // to prevent unneccessary arguments in changelog-service
                    ...change,
                    payload: extendPayloadWithMetaData(change.payload, encounter, practitioner, idx),
                })),
            ),
            switchMap(changes =>
                makeDefaultAsyncActionEffect(
                    forkJoin(changes.map(change => this.changeLogApi.saveChange(change, change.payload.encounterId))),
                    ChangeLogAction.saveChangesAction,
                ),
            ),
        ),
    );

    /**
     * Persist changes effect
     */
    persistChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.persistChangesAction.action),
            withLatestFrom(this.authService.user$),
            switchMap(([, userBeforeLogin]) =>
                this.authService.authenticate(AuthFlow.verify).pipe(
                    switchMap(rfid => {
                        return this.authService.user$.pipe(
                            map(userAfterLogin => [rfid, userBeforeLogin.id !== userAfterLogin.id]),
                            take(1),
                        );
                    }),
                ),
            ),
            withCurrentEncounterId(),
            concatLatestFrom(() => this.store.select(ChangeLogSelectors.getChanges)),
            switchMap(
                ([[[rfid, hasUserChangedDuringPersist], encounterId], sortedChanges]: [
                    [[string, boolean], string],
                    ChangeLogEntry<ChangeLogModel>[],
                ]) => {
                    const hasAllRequiredPermissions =
                        this.changeLogService.checkPendingChangesModelsPermissions(sortedChanges);

                    if (!hasAllRequiredPermissions) {
                        if (hasUserChangedDuringPersist) {
                            this.authService.logOut(new AppError('No permissions'), true, hasUserChangedDuringPersist);
                            return EMPTY;
                        }

                        // INFO: if user do not have permissioin to edit some model we redirect to "Access Denied" page and show warning alert
                        this.messageService.warnToast(
                            this.translateService.instant('warnings.notEnoughtPermissionsToSavePendingChanges'),
                        );

                        this.store.dispatch(
                            ChangeLogAction.persistChangesAction.failedAction({
                                error: new AppError('No permissions'),
                            }),
                        );

                        this.store.dispatch(
                            RouterActions.navigateAction({
                                path: [ROUTES_MAP_BY_FEATURE.UNAUTHORIZED],
                                extras: {
                                    state: { skipDiscardDialog: true },
                                },
                            }),
                        );

                        return EMPTY;
                    }

                    if (rfid) {
                        return makeDefaultAsyncActionEffect(
                            this.changeLogApi.persistChanges(rfid, encounterId).pipe(
                                tap(() => {
                                    if (hasUserChangedDuringPersist) {
                                        this.authService.logOut(null, true, hasUserChangedDuringPersist);
                                    }
                                }),
                            ),
                            ChangeLogAction.persistChangesAction,
                        );
                    } else {
                        return [ChangeLogAction.persistChangesAction.clearAction()];
                    }
                },
            ),
        ),
    );

    /**
     * Persist changes succeeded effect
     */
    persistChangesSucceeded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withCurrentEncounterId(),
                tap(([action, encounterId]) => {
                    const failedItems = action.payload.failed;
                    if (failedItems?.length) {
                        this.messageService.errorToast('errors.stageChanges', {
                            originalError: {
                                description: 'Failed items ids and reasons',
                                failedItems,
                            },
                        });
                    }
                    // Reload current encounter;
                    // // FIXME:COMBINED
                    // this.store.dispatch(EncountersActions.loadSingleEncounter({ encounterId }));
                    this.store.dispatch(
                        ChangeLogAction.clearPersistedSucceededModels({ ids: action.payload.succeeded }),
                    );
                }),
                tap(() => localStorage.removeItem('changeLogTags')),
            ),
        { dispatch: false },
    );

    /**
     * Discard changes effect
     */
    discardChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.discardChangesAction.action),
            switchMap(({ ids }) => {
                return (
                    ids?.length
                        ? this.practitioner$.pipe(map(user => user.rfid))
                        : this.authService.authenticate(AuthFlow.verify)
                ).pipe(
                    withLatestFrom(this.currentEncounter$.pipe(map(e => e?.id))),
                    switchMap(([rfid, encounterId]) => {
                        // FIXME: FIXME: FIXME: FIXME: FIXME: REFACTOR REDUCER TO ENTITY ADAPTER
                        // TODO: TODO: TODO: TODO: TODO: TODO: REFACTOR REDUCER TO ENTITY ADAPTER
                        if (rfid) {
                            if (!ids?.length) {
                                return rfid
                                    ? makeDefaultAsyncActionEffect(
                                          this.changeLogApi.discardChanges(rfid, encounterId, ids),
                                          ChangeLogAction.discardChangesAction,
                                      )
                                    : [ChangeLogAction.persistChangesAction.clearAction()];
                            }

                            return this.changeLogApi.discardChanges(rfid, encounterId, ids).pipe(
                                withLatestFrom(this.store.select(ChangeLogSelectors.getSortedChanges)),
                                map(([, sortedChanges]) => {
                                    const idsSet = new Set(ids);
                                    const payload = sortedChanges.filter(change => !idsSet.has(change.modelId));
                                    return ChangeLogAction.loadChangesAction.succeededAction({ payload });
                                }),
                                catchError(error => of(ChangeLogAction.discardChangesAction.failedAction({ error }))),
                            );
                        } else {
                            return [ChangeLogAction.persistChangesAction.clearAction()];
                        }
                    }),
                );
            }),
            tap(() => localStorage.removeItem('changeLogTags')),
        ),
    );

    /**
     * Constructor
     *
     * @param store Store<EncounterFeatureState>,
     * @param actions$ Actions
     * @param changeLogApi ChangeLogApi
     * @param messageService MessageService
     * @param authService AuthService
     * @param changeLogService ChangeLogService
     * @param translateService TranslateService
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private changeLogApi: ChangeLogApi,
        private messageService: MessageService,
        private authService: AuthService,
        private changeLogService: ChangeLogService,
        private translateService: TranslateService,
    ) {}
}
