import { Inject, Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ROUTES_MAP_BY_FEATURE } from '@environment';
import { createEffect, ofType } from '@ngrx/effects';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { exhaustMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthErrors, NAVIGATION, NavigationService, Platform, PLATFORM, stringify } from '@mona/shared/utils';
import { RouterSelectors } from '@mona/store';
import { DialogService } from '@mona/ui';
import { AuthService, TokenStorageService } from '../../services';
import { AuthActions } from '../actions';
import * as AuthSelectors from '../selectors';
import { BaseAuthEffects } from './base-auth.effects';

/**
 * Effect class for auth effects
 */
@Injectable({ providedIn: 'root' })
export class LogoutEffects extends BaseAuthEffects {
    logOut$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.logOut),
                withLatestFrom(
                    this.store.select(AuthSelectors.selectIsAuthenticated),
                    this.store.select(RouterSelectors.selectUrl),
                ),
                tap(
                    ([
                        { error, isRelogin, skipDiscardDialog, isUserChangedDuringPersist },
                        isAuthenticated,
                        urlBeforeLogout,
                    ]) => {
                        if (isRelogin) {
                            return;
                        }

                        const showLockscreenAsOverlay = !error && isAuthenticated && this.platform.isElectron;
                        const currentUrl = this.navigationService.currentLocationPath;
                        const currentLocationState = this.navigationService.currentLocationState;
                        if (error) {
                            this.logger.warn(
                                'LOGOUT: full log out should happen, auth error is present, lock screen no overlay',
                                stringify(error),
                                currentUrl,
                                stringify(currentLocationState),
                            );
                        }

                        let path = ROUTES_MAP_BY_FEATURE.LOCK_SCREEN + '?full=true&';

                        const extras: NavigationExtras = {
                            state: { ...currentLocationState, urlBeforeLogout },
                        };
                        if (skipDiscardDialog) {
                            extras.state.skipDiscardDialog = true;
                        }
                        if (isUserChangedDuringPersist) {
                            path += `different-user-persist=${error ? 'failed' : 'persisted'}`;
                        }
                        if (error?.status === 403) {
                            path = ROUTES_MAP_BY_FEATURE.ERROR;
                        } else if (showLockscreenAsOverlay) {
                            path = ROUTES_MAP_BY_FEATURE.LOCK_SCREEN;
                            extras.state.urlBeforeLogout = urlBeforeLogout;
                        } else {
                            extras.state.urlBeforeLogout =
                                this.platform.isElectron || error ? undefined : urlBeforeLogout;
                        }

                        if (error?.status === 401 && error?.error === AuthErrors.TOKEN_EXPIRED) {
                            this.messageService.errorToast('auth.error.tokenExpired.description');
                        }
                        this.navigationService.navigateByUrl(path, extras);
                    },
                ),
                exhaustMap(() => this.authApi.logout()),
            );
        },
        { dispatch: false },
    );

    logOutSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.logOutSuccess),
                tap(() => {
                    this.dialogService.closeAll();
                    this.clearUserData();
                    this.clearOverlays();
                    // clear app state on logout
                    const { navigationId } = this.navigationService.currentLocationState ?? {};
                    if (navigationId > 3) {
                        this.store.dispatch({ type: 'APP_STATE:CLEAR_STATE' });
                    }
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param permissionsService NgxPermissionsService
     * @param rolesService
     * @param dialogService
     * @param tokenStorageService
     * @param authService
     * @param platform
     * @param navigationService
     */
    constructor(
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService,
        private dialogService: DialogService,
        private tokenStorageService: TokenStorageService,
        private authService: AuthService,
        @Inject(PLATFORM) private platform: Platform,
        @Inject(NAVIGATION) private navigationService: NavigationService,
    ) {
        super();
    }

    /** Clears tokens and permissions if present */
    private clearUserData() {
        this.tokenStorageService.removeTokens();
        this.permissionsService.flushPermissions();
        this.rolesService.flushRoles();
    }

    /**
     * Cear all overlays
     *
     * NOTE: needed for Electron app, because it doesn't close overlays on auto logout(timeout)
     */
    private clearOverlays() {
        // NOTE: close overlay elements on autolock
        setTimeout(() => {
            const overlay = document.querySelector('.cdk-overlay-container');

            if (overlay?.children?.length) {
                const overlayChilds = Array.from(overlay.children);

                overlayChilds.forEach(child => {
                    (child as HTMLElement)?.click();
                });
            }
        }, 100);
    }
}
