import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Blob } from 'node-fetch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { ReportType } from '@mona/models';
import {
    ApiLocation,
    ApiReport,
    transformApiReportLocations,
    transformApiReportTypes,
} from './transforms/reports.transform';

/**
 * API abstraction layer for the export reports list API
 */
@Injectable({
    providedIn: 'root',
})
export class ReportsApi {
    serverUrl = 'http://smilecdr:8000';
    reportName = 'disaster_recovery';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Loads reports list
     */
    loadReportsList(): Observable<ReportType[]> {
        return this.http
            .get<ApiReport[]>('/reporting-engine/list-reports')
            .pipe(map(results => transformApiReportTypes(results)));
    }

    /**
     * Load reports export locations
     */
    loadReportsExportLocations(): Observable<any[]> {
        return this.http
            .get<ApiLocation[]>('/reporting-engine/store-locations')
            .pipe(map(results => transformApiReportLocations(results)));
    }

    /**
     * Export full report
     *
     * @param report
     * @param location
     * @param encounterId
     * @param timezone
     */
    exportReport(report, location, encounterId: string, timezone: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
            }),
            responseType: 'blob',
        };

        return this.http.post<Blob>(
            '/reporting-engine/generate-report',
            {
                fhirserver: this.serverUrl,
                output: location,
                timezone, // example: "Europe/Berlin",
                encounter: encounterId,
                report, // report name
            },
            httpOptions,
        );
    }
}
