import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthFlow, AuthService } from '@mona/auth';
import { isErrorStatusConflict } from '@mona/shared/utils';
import { AppState } from '@mona/store';
import { DialogService } from '@mona/ui';
import { UpdateRfidComponent } from '../../components';
import { AuthState, AuthTypeEnum, User } from '../../models';
import { AuthActions } from '../actions';
import { BaseAuthEffects } from './base-auth.effects';

type State = AppState & { auth: AuthState };

/**
 * Effect class for auth effect
 */
@Injectable({ providedIn: 'root' })
export class RfidEffects extends BaseAuthEffects {
    /**
     *
     * @param dialogService
     * @param authService
     */
    constructor(private dialogService: DialogService, private authService: AuthService) {
        super();
    }

    /**
     * emulate Rfid effect to dispatch scan
     *
     */
    emulateRfid$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.emulateRfid),
                tap(({ rfid }) => {
                    this.store.dispatch(AuthActions.scanRfid({ rfid }));
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * verifyRFID effect
     */
    verifyRfid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.verifyRfid),
            exhaustMap(({ rfid, dismissToast }) => {
                return this.authApi.verifyRfId(rfid).pipe(
                    map(result => AuthActions.verifyRfidSuccess({ ...result, dismissToast })),
                    catchError(error => of(AuthActions.verifyRfidFailure({ error }))),
                );
            }),
        ),
    );

    /**
     * verifyRFID succeeded effect
     *
     */
    verifyRfidSucceeded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.verifyRfidSuccess),
            tap(({ rfid, dismissToast }) => {
                if (rfid && !dismissToast) {
                    this.messageService.successToast('apps.settings.messages.saveSuccess');
                }
            }),
            map(({ rfid }) => {
                // INFO: this shoud be not `{ dispatch: false }` because we were listening for `verifyRfidSucceess` to close auth dialog
                return AuthActions.authenticateClose({ rfid });
            }),
        );
    });

    /**
     * verifyRFID failed effect
     */
    verifyRfidFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.verifyRfidFailure),
            filter(({ error }) => isErrorStatusConflict(error)),
            withLatestFrom(this.store.select((state: State) => state.auth.rfid)),
            map(([, rfid]) => AuthActions.relogIn()),
        );
    });

    /**
     * registerRfid effect
     */
    registerRfid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.registerRfid),
            exhaustMap(action =>
                this.authApi.registerRfiD(action.rfid, action.pin).pipe(
                    map((user: User) => AuthActions.registerRfidSuccess({ user })),
                    catchError(error => of(AuthActions.registerRfidFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * registerRfid succeeded effect
     */
    registerRfidSucceeded$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.registerRfidSuccess),
                tap(({ user }) =>
                    this.messageService.successToast('shell.welcomeMessage', {
                        displayName: user?.displayName,
                    }),
                ),
            );
        },
        { dispatch: false },
    );

    /**
     * registerRfid failed effect
     */
    registerRfidFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.registerRfidFailure),
                tap((error: any) => {
                    const errorsMap = {
                        'practitioner.rfid_already_assigned': 'errors.auth.rfid_already_assigned',
                        'practitioner.pin_not_found': 'errors.auth.pin_not_found',
                    };
                    this.messageService.errorToast(errorsMap[error.errorCode]);
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * updateRfid effect
     */
    updateRfid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateRfid),
            switchMap(() => this.dialogService.open(UpdateRfidComponent)),
            filter(rfid => !!rfid),
            switchMap(rfid =>
                this.authService
                    .authenticate(AuthFlow.verify, AuthTypeEnum.Credentials)
                    .pipe(map(auth => ({ rfid, isAuthenticated: !!auth }))),
            ),
            exhaustMap(({ rfid }) =>
                this.authApi.updateRfiD(rfid).pipe(
                    map(() => AuthActions.updateRfidSuccess({ rfid })),
                    catchError(error => of(AuthActions.updateRfidFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * updateRfid succeeded effect
     */
    updateRfidSucceeded$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.updateRfidSuccess),
                tap(() => this.messageService.successToast('apps.userSettings.rfid.updateRfid.successToast')),
            );
        },
        { dispatch: false },
    );

    /**
     * updateRfid failed effect
     */
    updateRfidFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.updateRfidFailure),
                tap(({ error }: { error: any }) => {
                    const errorsMap = {
                        'practitioner.rfid_already_assigned': 'errors.auth.rfid_already_assigned',
                        'practitioner.pin_not_found': 'errors.auth.pin_not_found',
                    };
                    this.messageService.errorToast(errorsMap[error.errorCode]);
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * discardRfid effect
     */
    discardRfid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.discardRfid),
            exhaustMap(action =>
                this.authApi.discardRfiD(action.rfid).pipe(
                    map(() => AuthActions.discardRfidSuccess()),
                    catchError(error => of(AuthActions.discardRfidFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * discardRfid succeeded effect
     */
    discardRfidSucceeded$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.discardRfidSuccess),
                tap(() => this.messageService.successToast('apps.userSettings.rfid.discardRfid.successToast')),
            );
        },
        { dispatch: false },
    );
}
